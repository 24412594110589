import React, { useEffect, useRef } from "react";
import { Link, graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image"
import { AnchorLink } from "gatsby-plugin-anchor-links";
// styles
import styled from '@emotion/styled'
import { css } from '@emotion/react'
// slider
import Slider from "react-slick"
// isMobile
import useWidth from './../hooks/useWidth';

import infoContact from "./../data/infoContact.json"
import imgHeader from "../images/radiografia.png"
import intro_mobile from "../video/intro_mobile.mp4"
import { useState } from "react";



const Header = () => {

    // const data = useStaticQuery(
    //     graphql`
    //       query {
    //         desktop: file(relativePath: { eq: "header.png" }) {
    //           childImageSharp {
    //             fluid(quality: 90, maxWidth: 1920) {
    //               ...GatsbyImageSharpFluid_withWebp
    //             }
    //           }
    //         },
    //         allStrapiHomePage {
    //             nodes {
    //                 tituloHeader
    //                 subtituloHeader
    //                 telefono
    //                 telefonoWhatsapp
    //             }
    //         },
    //         allStrapiCarrusel {
    //             edges {
    //                 node {
    //                     Subtitulo
    //                     Titulo
    //                     Imagen {
    //                       url
    //                     }
    //                 }
    //             }
    //         }
    //     }
    // `
    // )

    const isMobile = useWidth();
    // Set ImageData.

    // const imageData = data.desktop.childImageSharp.fluid
    // const { tituloHeader, subtituloHeader, telefono, telefonoWhatsapp } = data.allStrapiHomePage.nodes[0]
    // const DataCarrousel = data.allStrapiCarrusel.edges

    const ContainerLogo = styled.div`
        display: flex;
        justify-content: center;
        padding: 2.5rem;
        padding-bottom:0;
    `

    const ItemsSlider = styled.div`
        height:100vh;
        background-size:cover;
        background-repeat:no-repeat;
        opacity:inherit!important;
    `

    const Subtitle = styled.p`
        font-size: 3rem;
        color: #164688;
        text-align:right;
        padding-top:1rem;
        padding-right:5%;
        @media(max-width:768px){
            text-align:center;
            padding-right:0 !important;
        }
        @media(max-width:1200px){
            font-size:2.3rem;
        }
    `

    const Title = styled.h1`
        margin:0;
        text-align:right;
        font-size:4rem;
        text-transform:uppercase;
        color:#0024B6;
        padding-right:5%;
        @media(max-width:768px){
            padding-right:0 !important;
            font-size:5rem;
            text-align:center;
        }
        @media(max-width:1200px){
            font-size:3rem;
        }
        @media(max-width:1757px){
            font-size:3rem;
        }

    `

    const ALinkMedia = styled.a`
    color: white;
    text-decoration: none;
    :hover{
        color: white;
        transition: color .15s ease-in-out;
    }
        span{
            margin-top: 20px;
        }
        span.btn-whatsapp{
            background-color: #24cc62;
            padding: 7px 30px;
            border-radius: 20px;
            margin-top: 20px !important;
        }

    `

    const ButtonCallToAction = styled.a`
        border-radius:100px;
        border:0;
        background-color:#0024B6;
        color:#FFF;
        font-weight:bold;
        transition: padding .6s ease-in-out!important;
        font-size:3rem;
        padding:1.5rem 3rem!important; 
        z-index:100000;
        :hover{
            padding: 1.5rem 4rem!important;
            transition: padding .6s ease-in-out!important;
            color:#FFF;
        }
        @media(min-width:768px){
            margin-right:5%;
            font-size:2rem;
            padding:1rem 2rem!important;
            :hover{
            padding: 1rem 4rem!important;
            transition: padding .6s ease-in-out!important;
            }
        }
`

    const Item = styled.div`
        display: grid;
        column-gap:0rem;
        height: 90vh;
        align-content: center;
        padding:0 3rem;
        @media(min-width:768px){
            grid-template-columns: 1fr 1fr;
        }
        @media(min-width:1200px){
            grid-template-columns: 2fr 1.5fr;
        }
        @media(min-width:1400px){
            grid-template-columns: 2fr 1fr;
        }
    `
    const ContainerVideo = styled.div`
        video{
            width: 100%;
            height: auto;
        }
    `

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        // fade: true,
        cssEase: 'linear',
        autoplay: true,
        autoplaySpeed: 4000,
        pauseOnHover: false,
    }

    // let aleatorio = Math.floor(Math.random() * (DataCarrousel.length));
    // let seleccion = DataCarrousel[aleatorio]
    // let itemMobil = [seleccion]

    let DataCarrousel = [
        {
            title: "RADIOLOGÍA DENTO MAXILO FACIAL",
            Subtitle: "Agenda tu cita Aquí",
            link: "https://6a0300f1f6a00e61667bfcec4c36bae70f52afe0.agenda.softwaredentalink.com/agendas/agendamiento",
            imagenBg: imgHeader,
            video: intro_mobile
        }
    ]


    const [scrollTop, setScrollTop] = useState(0);

    const videoEl = useRef(null);

    const attemptPlay = () => {
        videoEl &&
            videoEl.current &&
            videoEl.current.play().catch(error => {
                console.error("Error attempting to play", error);
            });
    };

    useEffect(() => {
      const handleScroll = (event) => {
        // setScrollTop(window.scrollY);
        attemptPlay()
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, []);

    return (

        <header css={css`margin-top:55px;`}>
            <ContainerLogo>
                <a href="/"><StaticImage src="../images/logo.png" alt="Rayodent logo" /></a>
            </ContainerLogo>
            <div css={css`text-align:center;justify-content:center; padding-bottom:1.5rem;`}>
                {/* <p css={css`margin-bottom:0;`}><strong>LLÁMANOS</strong></p> */}
                {/* <ALinkMedia css={css`font-size:2.6rem; @media(min-width:768px){font-size:2rem!important}`} href={"tel:" + infoContact.phone} rel="noopener noreferrer" target="_blank">{infoContact.phone}</ALinkMedia> */}
                <p css={css`margin-bottom:0;`}><strong>WHATSAPP</strong></p>
                <ALinkMedia css={css`font-size:2.6rem; @media(min-width:768px){font-size:2rem!important}`} href={`https://wa.me/${infoContact.whatsapp.replace(/ /g, "")}?text=Buen%20día,%20me%20gustaría%20reservar%20una%20consulta.`} rel="noopener noreferrer" target="_blank"><span className="btn-whatsapp">{infoContact.whatsapp}</span></ALinkMedia>
            </div>
            <div>
                <Slider {...settings} className="overflow-hidden">
                    {
                        isMobile ?
                            DataCarrousel.map((item) => {
                                return (

                                    <ContainerVideo>
                                        <video
                                            // onEnded={alert("finalizo")}
                                            style={{ maxWidth: "100%", width: "800px", margin: "0 auto" }}
                                            playsInline
                                            controls={false}
                                            webkit-playsinline="true"
                                            autoPlay={true}
                                            muted={false}
                                            alt="All the devices"
                                            src={item.video}
                                            ref={videoEl}
                                        />
                                    </ContainerVideo>

                                    // <ItemsSlider key={Math.floor(Math.random() * 100) + 1} css={css`background-image:linear-gradient(to right, transparent, #FFFFFF),url(${'"' + item.imagenBg + '"'});`}>
                                    //     <Item>
                                    //         <div css={css`display:none; @media(min-width:768px){display:block;}`}></div>
                                    //         <div css={css`text-align:center; @media(min-width:768px){text-align:right;}`}>
                                    //             <Title>
                                    //                 {item.title}
                                    //             </Title>
                                    //             <Subtitle>
                                    //                 {item.Subtitle}
                                    //             </Subtitle>
                                    //             <ButtonCallToAction href={item.link} target="_blank" title="Reserva" css={css`text-decoration:none;`}>Reserva Online</ButtonCallToAction>
                                    //         </div>
                                    //     </Item>
                                    // </ItemsSlider>
                                )
                            })
                            :
                            DataCarrousel.map((item) => {
                                return (
                                    <ItemsSlider key={Math.floor(Math.random() * 100) + 1} css={css`background-image:linear-gradient(to right, transparent, #FFFFFF),url(${'"' + item.imagenBg + '"'});`}>
                                        <Item>
                                            <div css={css`display:none; @media(min-width:768px){display:block;}`}></div>
                                            <div css={css`text-align:center; @media(min-width:768px){text-align:right;}`}>
                                                <Title>
                                                    {item.title}
                                                </Title>
                                                <Subtitle>
                                                    {item.Subtitle}
                                                </Subtitle>
                                                <ButtonCallToAction href={item.link} target="_blank" title="Reserva" css={css`text-decoration:none;`}>Reserva Online</ButtonCallToAction>
                                            </div>
                                        </Item>
                                    </ItemsSlider>
                                )
                            })
                    }
                </Slider>
                {/* <div css={css` position:relative; margin-top:-16.5%; z-index:1;`}>
                    <svg id="Layer_1" data-name="Layer 1" viewBox="0 0 1923.43 322.56">
                        <defs>
                            <clipPath id="clip-path-svg" transform="translate(0.52 0)">
                                <path css={css`fill:none;`} d="M.35,321.85-.34,57.33c15,38.74,61.63,136.52,167.58,201.72,33.24,20.46,195,62.92,240.26,62.8,38.06-.11,154.4-26,176.75-34.65a758.69,758.69,0,0,0,72.67-32.88c134.78-69,261.39-173.1,440.86-223.49l4.55-2.67a775.91,775.91,0,0,1,94.34-19.7A813.62,813.62,0,0,1,1301.45.14c393-6.4,620.38,216.45,620.38,216.45l.08,105.26Z" />
                            </clipPath>
                        </defs>
                        <g css={css`clip-path:url(#clip-path-svg);`}>
                            <rect css={css`fill:#fff;`} x="0.18" width="1922.26" height="321.85" />
                        </g>
                        <path css={css`fill:#fceedf;`} d="M1921.22,322.55l0-.77a23.58,23.58,0,0,0,.65-3.95q.06-60.82,0-121.65h.67q0,61.79-.07,123.56C1922.54,320.63,1924,322.77,1921.22,322.55Z" transform="translate(0.52 0)" />
                        <path css={css`fill:#f7bd84;`} d="M1921.94,196.18q0,60.82,0,121.65a23.58,23.58,0,0,1-.65,3.95c-5.76-5.11-11.45-10.31-17.32-15.3-9.92-8.45-19.87-16.87-30-25.1s-20.47-16.57-31-24.5c-12.73-9.61-25.56-19.1-38.66-28.19s-26.6-17.94-40.16-26.52c-14.25-9-28.59-18-43.26-26.3-16-9.09-32.22-17.72-48.6-26-14.14-7.19-28.48-14-43-20.35-15.73-6.84-31.71-13.11-47.74-19.21C1569.84,105.82,1558,101.7,1546,98c-13.82-4.22-27.79-8-41.79-11.56-13-3.31-26-6.56-39.12-9-14.76-2.78-29.64-5-44.55-6.84-13.3-1.64-26.69-2.88-40.07-3.47-19.84-.86-39.71-1.52-59.56-1.25-13.59.18-27.16,2.07-40.74,3.22-27.28,2.31-54.06,7.59-80.55,14.22a538.2,538.2,0,0,0-81,27.06c-16.18,6.92-32.06,14.55-48,21.94-4.07,1.88-8,4-12,6.07a6,6,0,0,1-.39-.49c2.84-2.63,5.44-5.59,8.56-7.83,14.54-10.4,28.89-21.14,44-30.73,13.73-8.74,28.06-16.64,42.57-24A464.47,464.47,0,0,1,1197.81,55c21-8,42.23-15.6,63.82-21.75,19.84-5.66,40.22-9.49,60.49-13.51,9.94-2,20.15-2.7,30.26-3.69,13.56-1.33,27.14-2.57,40.74-3.46,9.14-.59,18.33-.81,27.49-.66,13.92.21,27.85.54,41.74,1.47,12.91.87,25.8,2.32,38.64,4,12,1.55,24,3.52,35.9,5.66,9.64,1.74,19.18,4,28.76,6.07,6.43,1.39,12.9,2.66,19.24,4.39,12.67,3.47,25.29,7.15,37.88,10.89,9.32,2.77,18.67,5.5,27.82,8.75,14.42,5.13,28.85,10.28,43,16.15,19.9,8.27,39.72,16.77,59.19,26,17.46,8.27,34.49,17.43,51.51,26.58,12.61,6.79,25,14,37.3,21.38,11.89,7.13,23.59,14.58,35.23,22.12,11,7.15,21.9,14.55,32.78,21.92C1913.77,190.15,1917.82,193.22,1921.94,196.18Z" transform="translate(0.52 0)" />
                        <path css={css`fill:#f7bd84;`} d="M1058.59,138.4l-1.36.72-.27-.27,1.24-.93Q1058.38,138.16,1058.59,138.4Z" transform="translate(0.52 0)" />
                        <path css={css`fill:#f7bd84;`} d="M1057.23,139.12l-.68.15.41-.42Z" transform="translate(0.52 0)" />
                        <path css={css`fill:#0a71cd;`} d="M-.52,36.06c3.39,6.62,6,12.52,9.28,18,5.71,9.45,11.62,18.79,17.9,27.86A213.68,213.68,0,0,0,43.8,104.19c10.13,11.15,20.76,21.88,31.57,32.39,13.86,13.49,29.4,24.94,45.54,35.56a417,417,0,0,0,70.72,37.22C231,225.54,271.94,235.93,314,242a672.57,672.57,0,0,0,118.13,6.31c50.8-1.64,100.59-10,149.44-23.86a904.1,904.1,0,0,0,114.2-41.21q41.07-17.89,81.91-36.29C808.51,133.12,839,118.43,870,105.23c32.4-13.76,65.19-26.63,98.12-39.08,29.71-11.23,60.2-20.19,91-28.06,13.15-3.35,26.4-6.32,39.61-9.43a4.89,4.89,0,0,1,2.85.21c-6.7,2.59-13.47,5-20.11,7.8-25,10.39-50.18,20.52-75,31.45Q976.18,81.5,946.59,96.54c-26.12,13.28-52,27.09-77.72,41.12-18.25,10-36.13,20.56-54.09,31-19.84,11.54-39.49,23.4-59.39,34.83-19.71,11.32-39.59,22.37-59.48,33.37C661,256.19,625,273.25,587.47,287.1a554.33,554.33,0,0,1-96.21,26.15,546.94,546.94,0,0,1-114.83,7.83q-38.54-1.4-77-5.06c-29.28-2.77-58.23-8.08-86.95-14.47a813.51,813.51,0,0,1-91.13-26.05,672.9,672.9,0,0,1-78.52-32.78c-14-7-27.46-14.9-41.1-22.52a4.68,4.68,0,0,1-2.16-3.31C-.53,156.93-.52,97-.52,36.06Z" transform="translate(0.52 0)" />
                    </svg>
                </div> */}
            </div>
        </header >
    );
};

export default Header;

